import "./App.css";

import { Analytics } from "@vercel/analytics/react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Pages/Home";
import { Box, ChakraProvider, Container } from "@chakra-ui/react";
import theme from "./Theme/theme";
import { useState } from "react";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
]);

function App() {
  return (
    <>
      <ChakraProvider theme={theme}>
        <Box
          minHeight="100vh"
          bgGradient="linear(to-br, #ffffff, rgba(59,148,214,0.2))"
          color="text.primary"
        >
          <Container maxW="4xl" py={10}>
            <RouterProvider router={router} />
          </Container>
        </Box>
      </ChakraProvider>
    </>
  );
}

export default App;
