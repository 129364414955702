import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  initialColorMode: "light",
  useSystemColorMode: false,
  colors: {
    brand: {
      900: "#1A5DAB", // Dark Blue
      800: "#237BCB", // Medium Dark Blue
      400: "#3B94D6", // Slightly Darker Medium Blue
      100: "#D6E9FA", // Light Blue
    },
    text: {
      primary: "#333333", // Dark Gray for Text
    },
  },
  fonts: {
    heading: "JetBrains Mono, monospace",
    body: "JetBrains Mono, monospace",
    plain: "Inter, sans-serif",
    chinese: "Noto Sans SC, sans-serif",
  },
});

export default theme;
