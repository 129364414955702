import {
  Button,
  HStack,
  Image,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { ScheduleConsultationButton, TextLogo } from "./Navbar";

const Footer = ({ isChinese, setIsChinese }) => {
  const [isLargerThanMd] = useMediaQuery("(min-width: 768px)");

  if (isLargerThanMd)
    return (
      <VStack w="100%" alignItems="left">
        <HStack justifyContent="space-between" alignItems="flex-start" mt={20}>
          <VStack alignItems="left">
            <TextLogo />
          </VStack>
          <HStack gap={5}>
            {/* change language */}
            <Button
              size="sm"
              variant="outline"
              borderRadius="xl"
              fontWeight="medium"
              fontFamily={!isChinese && "chinese"}
              leftIcon={
                <Image
                  src={
                    isChinese
                      ? "http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
                      : "http://purecatamphetamine.github.io/country-flag-icons/3x2/CN.svg"
                  }
                  w={6}
                />
              }
              onClick={() => {
                setIsChinese(!isChinese);
              }}
            >
              {isChinese ? "English" : "中文"}
            </Button>

            {/* call to action */}
            <ScheduleConsultationButton />
          </HStack>
        </HStack>

        {/* bottom area */}
        <HStack justifyContent="space-between" mt={5}>
          <Text pl={2}>justin@silkweb.dev</Text>
          <Text>© 2024 Silkweb Agency. All rights reserved.</Text>
        </HStack>
      </VStack>
    );

  // not larger than md -- is mobile
  return (
    <VStack w="100%" alignItems="left">
      <HStack alignItems="center" justifyContent="space-between" mt={12}>
        {/* change language */}
        <Button
          size="sm"
          variant="outline"
          borderRadius="xl"
          fontWeight="medium"
          fontFamily={!isChinese && "chinese"}
          leftIcon={
            <Image
              src={
                isChinese
                  ? "http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
                  : "http://purecatamphetamine.github.io/country-flag-icons/3x2/CN.svg"
              }
              w={6}
            />
          }
          onClick={() => {
            setIsChinese(!isChinese);
          }}
        >
          {isChinese ? "English" : "中文"}
        </Button>

        {/* button */}
        <ScheduleConsultationButton isChinese={isChinese} />
      </HStack>

      <Text pl={2} mt={5}>
        Email: justin@silkweb.dev
      </Text>
      <Text pl={2}>© 2024 Silkweb Agency. All rights reserved.</Text>
    </VStack>
  );
};

export default Footer;
