import {
  Button,
  Flex,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
  useToast,
  useToken,
} from "@chakra-ui/react";
import Navbar from "../Nav/Navbar";
import { TypeAnimation } from "react-type-animation";
import {
  FiAward,
  FiDollarSign,
  FiLayout,
  FiMail,
  FiTool,
} from "react-icons/fi";
import { useState } from "react";
import { PortfolioItem } from "./PortfolioItem";
import { ProfileCard } from "./ProfileCard";
import Footer from "../Nav/Footer";
import copy from "copy-to-clipboard";

const Home = () => {
  const [brand900] = useToken("colors", "brand.100");
  const [isChinese, setIsChinese] = useState(false);
  const toast = useToast();

  return (
    <VStack>
      <Navbar isChinese={isChinese} setIsChinese={setIsChinese} />

      <Heading mt={20} size="2xl" color="brand.400">
        {">"}
        <Heading display="inline" color="text.primary" as="i" size="2xl">
          <TypeAnimation
            sequence={[250, "silk"]}
            wrapper="span"
            cursor={false}
            style={{
              fontSize: "1em",
              display: "inline-block",
              color: brand900,
            }}
          />
        </Heading>
        <Heading display="inline" color="brand.400" size="2xl">
          <TypeAnimation
            sequence={[600, "web"]}
            wrapper="span"
            cursor={true}
            style={{
              fontSize: "1em",
              display: "inline-block",
            }}
          />
        </Heading>
      </Heading>

      <Text
        as="i"
        fontSize={isChinese ? "lg" : "md"}
        fontWeight="medium"
        fontFamily={isChinese && "chinese"}
        color="gray.500"
        mt={4}
        px={{ base: 5, md: 10 }}
        textAlign="center"
      >
        {isChinese
          ? "网站设计、开发和管理机构"
          : "Web design and management agency"}
      </Text>
      <Text
        as="i"
        fontSize={isChinese ? "lg" : "md"}
        fontWeight="medium"
        fontFamily={isChinese && "chinese"}
        color="gray.500"
        px={{ base: 5, md: 10 }}
        textAlign="center"
      >
        {isChinese ? "定制解决方案" : "Specialized in small businesses"}
      </Text>
      <Text
        as="i"
        fontSize={isChinese ? "lg" : "md"}
        fontWeight="medium"
        fontFamily={isChinese && "chinese"}
        color="gray.500"
        px={{ base: 5, md: 10 }}
        textAlign="center"
      >
        {isChinese ? "位于南加州" : "Based in Orange County, California"}
      </Text>

      <Flex
        alignItems="flex-start"
        mt={16}
        w="100%"
        gap={16}
        direction={{ base: "column-reverse", md: "row" }}
      >
        {/* Profile Card */}
        <ProfileCard />

        <VStack w="100%" alignItems="left" gap={8}>
          <HStack alignItems="center" gap={6}>
            <Icon as={FiLayout} color="brand.400" boxSize={10} />
            <VStack alignItems="left">
              <Heading
                fontSize={isChinese ? "xl" : "lg"}
                fontWeight="medium"
                fontFamily={isChinese && "chinese"}
              >
                {isChinese ? "定制解决方案" : "Custom Solutions"}
              </Heading>
              <Text
                fontFamily={isChinese && "chinese"}
                fontSize={isChinese && "xl"}
              >
                {isChinese
                  ? "完全定制的网站，提供免费咨询。"
                  : "Completely custom websites with free consultation sessions."}
              </Text>
            </VStack>
          </HStack>

          <HStack alignItems="center" gap={6}>
            <Icon as={FiTool} color="brand.400" boxSize={10} />
            <VStack alignItems="left">
              <Heading
                fontSize={isChinese ? "xl" : "lg"}
                fontWeight="medium"
                fontFamily={isChinese && "chinese"}
              >
                {isChinese ? "专属专家" : "Dedicated Expertise"}
              </Heading>
              <Text
                fontFamily={isChinese && "chinese"}
                fontSize={isChinese && "xl"}
              >
                {isChinese
                  ? "直接与专属的个人网页开发人员联系。"
                  : "Have direct access to a dedicated, personal web developer."}
              </Text>
            </VStack>
          </HStack>

          {/* affordable excellence */}
          <HStack alignItems="center" gap={6}>
            <Icon as={FiDollarSign} color="brand.400" boxSize={10} />
            <VStack alignItems="left">
              <Heading
                fontSize={isChinese ? "xl" : "lg"}
                fontWeight="medium"
                fontFamily={isChinese && "chinese"}
              >
                {isChinese ? "实惠卓越" : "Affordable Excellence"}
              </Heading>
              <Text
                fontFamily={isChinese && "chinese"}
                fontSize={isChinese && "xl"}
              >
                {isChinese
                  ? "全面管理的专业网站，每年仅需250美元起。"
                  : "Fully managed professional websites, starting at $250/year."}
              </Text>
            </VStack>
          </HStack>

          <VStack alignItems="left">
            <Text
              fontFamily={isChinese && "chinese"}
              fontSize={isChinese ? "lg" : "md"}
            >
              {isChinese
                ? "如有任何疑问或问题，请发送电子邮件。"
                : "Email for any inquiries or questions."}
            </Text>
            <HStack
              alignItems="center"
              _hover={{ cursor: "pointer", color: "brand.400" }}
              onClick={() => {
                // copy email
                copy("justin@silkweb.dev");

                // show toast
                toast({
                  title: isChinese ? "邮箱已复制！" : "Email copied!",
                  description: isChinese
                    ? "justin@silkweb.dev 已复制到剪贴板。"
                    : "justin@silkweb.dev copied to clipboard.",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                  position: "top",
                });
              }}
              color="text.primary"
            >
              <Icon as={FiMail} color="brand.400" />
              <Text color="inherit">justin@silkweb.dev</Text>
            </HStack>
          </VStack>

          <Button
            w={{ base: "100%", md: "50%" }}
            bg="brand.400"
            color="white"
            borderRadius="xl"
            _hover={{
              bg: "brand.900",
              transform: "translateY(-2px)",
              boxShadow: "lg",
            }}
            onClick={() => {
              window.open(
                "https://calendly.com/zhaojustin/silkweb-website-consultation",
                "_blank"
              );
            }}
            fontFamily={isChinese && "chinese"}
          >
            {isChinese ? "安排咨询" : "Schedule Consultation"}
          </Button>
        </VStack>
      </Flex>

      {/* portfolio */}
      <VStack mt={24} alignItems="left" w="100%">
        <HStack alignItems="center" gap={4}>
          <Icon as={FiAward} color="brand.400" boxSize={8} />
          <Heading
            fontFamily={isChinese && "chinese"}
            fontSize={{
              base: isChinese ? "xl" : "lg",
              md: isChinese ? "lg" : "md",
            }}
          >
            {isChinese ? "往期客户" : "Past Clients"}
          </Heading>
        </HStack>

        <VStack gap={5} pt={10}>
          <PortfolioItem
            title={"NYU Beta Alpha Psi"}
            img={"./Home/portfolio/bapnyu-screenrecording.mp4"}
            linkText={"bapnyu.com"}
            linkHref={"https://bapnyu.com"}
            description={
              "A custom website created for NYU Stern School of Business honor society, Beta Alpha Psi."
            }
            descriptionChinese={
              "为纽约大学斯特恩商学院荣誉社团Beta Alpha Psi定制的网站。"
            }
            isChinese={isChinese}
          />
          <PortfolioItem
            title={"Babble NYC"}
            img={"./Home/portfolio/babble-screenrecording.mp4"}
            linkText={"babblenyc.com"}
            linkHref={"https://babblenyc.com"}
            description={
              "A custom, simple website created for a nomadic coffee shop chain in New York City."
            }
            descriptionChinese={"为纽约市一个流动咖啡店连锁店定制的简洁网站。"}
            isChinese={isChinese}
          />
          <PortfolioItem
            title={"Tomotime App"}
            img={"./Home/portfolio/tomotime-app-screenrecording.mp4"}
            linkText={"tomotime.app"}
            linkHref={"https://tomotime.app"}
            description={
              "A custom website made for a social media app for friend groups called Tomotime."
            }
            descriptionChinese={
              "为社交媒体应用Tomotime定制的网站，该应用专为好友群体设计。"
            }
            isChinese={isChinese}
          />
          <PortfolioItem
            title={"Selfie Snake"}
            img={"./Home/portfolio/selfiesnake-screenrecording.mp4"}
            linkText={"selfiesnake.com"}
            linkHref={"https://selfiesnake.com"}
            description={
              "From the same client, Tomotime, a custom web-app that allows users to share images and create selfie chains."
            }
            descriptionChinese={
              "来自同一客户Tomotime的定制网络应用，用户可以分享图片并创建自拍链。"
            }
            isChinese={isChinese}
          />
        </VStack>
      </VStack>

      <Footer isChinese={isChinese} setIsChinese={setIsChinese} />
    </VStack>
  );
};

export default Home;
