import { Circle, VStack, Text, Image, HStack, Icon } from "@chakra-ui/react";
import { FiMail } from "react-icons/fi";

export const ProfileCard = () => {
  return (
    <VStack
      w={{ base: "100%", md: "40%" }}
      p={5}
      bg="white"
      boxShadow="lg"
      borderRadius="xl"
    >
      <Image src={"/Home/profilepic.jpg"} w={40} h={40} borderRadius="50%" />
      <Text fontWeight="semibold" fontSize="2xl" mt={5}>
        Justin Zhao
      </Text>
      <Text fontSize="sm">Founder, Silkweb</Text>
      <Text fontSize="sm">Orange County, CA</Text>

      <HStack alignItems="center" gap={2} mt={2}>
        <Icon as={FiMail} />
        <Text fontSize="sm">justin@silkweb.dev</Text>
      </HStack>
    </VStack>
  );
};
