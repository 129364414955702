import {
  Box,
  Flex,
  Image,
  Link,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import ReactPlayer from "react-player";

export const PortfolioItem = ({
  title,
  linkText,
  img,
  linkHref,
  description,
  descriptionChinese,
  isChinese,
}) => {
  const [isLargerThanMd] = useMediaQuery("(min-width: 768px)");
  const playerWidth = isLargerThanMd ? 400 : "100%";

  return (
    <Flex
      gap={{ base: 0, md: 10 }}
      my={5}
      direction={{ base: "column", md: "row" }}
      alignItems={{ base: "baseline", md: "center" }}
      w="100%"
    >
      <Box w={{ base: "100%", md: 400 }} h={200}>
        <ReactPlayer
          width={playerWidth}
          height={200}
          url={img}
          muted={true}
          controls={false}
          loop={true}
          playing={true}
        />
      </Box>

      <VStack alignItems="left" gap={5}>
        <VStack alignItems="left">
          <Text
            fontWeight="semibold"
            fontSize={{ base: "lg", md: "2xl" }}
            mt={{ base: 5, md: 0 }}
          >
            {title}
          </Text>
          <Link color="blue.400" fontSize="md" href={linkHref} isExternal>
            {linkText}
          </Link>
        </VStack>
        <Text fontSize="md">
          {isChinese ? descriptionChinese : description}
        </Text>
      </VStack>
    </Flex>
  );
};
