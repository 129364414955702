import {
  Button,
  HStack,
  Heading,
  Image,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";

export const ScheduleConsultationButton = ({ isChinese }) => {
  return (
    <Button
      bg="brand.400"
      color="white"
      borderRadius="xl"
      size="sm"
      _hover={{
        bg: "brand.900",
        transform: "translateY(-2px)",
        boxShadow: "lg",
      }}
      onClick={() => {
        window.open(
          "https://calendly.com/zhaojustin/silkweb-website-consultation",
          "_blank"
        );
      }}
      fontFamily={isChinese && "chinese"}
    >
      {isChinese ? "安排咨询" : "Schedule Consultation"}
    </Button>
  );
};

export const TextLogo = ({ fontSize }) => {
  return (
    <HStack gap="0">
      <Heading color="brand.400" fontSize={fontSize ? fontSize : "2xl"}>
        {">"}
      </Heading>
      <Heading
        color="text.primary"
        as="i"
        fontSize={fontSize ? fontSize : "2xl"}
      >
        silk
      </Heading>
      <Heading color="brand.400" fontSize={fontSize ? fontSize : "2xl"}>
        web
      </Heading>
    </HStack>
  );
};

const Navbar = ({ isChinese, setIsChinese }) => {
  const [isLargerThanMd] = useMediaQuery("(min-width: 768px)");

  if (isLargerThanMd)
    return (
      <HStack w="100%" justifyContent="space-between" alignItems="flex-start">
        <VStack alignItems="left">
          <TextLogo />
        </VStack>
        <HStack gap={5}>
          {/* change language */}
          <Button
            size="sm"
            variant="outline"
            borderRadius="xl"
            fontWeight="medium"
            fontFamily={!isChinese && "chinese"}
            leftIcon={
              <Image
                src={
                  isChinese
                    ? "http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
                    : "http://purecatamphetamine.github.io/country-flag-icons/3x2/CN.svg"
                }
                w={6}
              />
            }
            onClick={() => {
              setIsChinese(!isChinese);
            }}
          >
            {isChinese ? "English" : "中文"}
          </Button>

          {/* call to action */}
          <ScheduleConsultationButton isChinese={isChinese} />
        </HStack>
      </HStack>
    );

  // not larger than md -- is mobile
  return (
    <HStack w="100%" alignItems="center" justifyContent="space-between">
      {/* change language */}
      <Button
        size="sm"
        variant="outline"
        borderRadius="xl"
        fontWeight="medium"
        fontFamily={!isChinese && "chinese"}
        leftIcon={
          <Image
            src={
              isChinese
                ? "http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
                : "http://purecatamphetamine.github.io/country-flag-icons/3x2/CN.svg"
            }
            w={6}
          />
        }
        onClick={() => {
          setIsChinese(!isChinese);
        }}
      >
        {isChinese ? "English" : "中文"}
      </Button>

      {/* button */}
      <ScheduleConsultationButton isChinese={isChinese} />
    </HStack>
  );
};

export default Navbar;
